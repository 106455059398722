// TODO add preloader code
// use .loader to instantiate

#loader-wrapper {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
    z-index: 2000;
}

/* Loaded */
.loaded {
    #loader-wrapper {
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 1s 1s ease-out;
        transition: all 1s 1s ease-out;
    }
    #loader {
        opacity: 0;
        -webkit-transition: all 1s ease-out;
        transition: all 1s ease-out;
    }
}

//.no-js #loader-wrapper {
//    display: none;
//}
//.no-js #loader {
//    display: none;
//}



#loader {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 40vh;
    margin: auto;
    border-radius: 50%;
    //overflow: hidden;
    box-shadow: 0 0 0.5px #fff;
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: linear-gradient(@brand-secondary, white 20%);
        animation: spin 4s infinite cubic-bezier(0.840, 0.200, 0.200, 0.840);
    }
    &:after {
        content: "";
        position: absolute;
        width: 94%;
        height: 90%;
        top: -1%;
        left: 3%;
        right: 3%;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: inset 0 0 1px #fff;
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
