@import "../css/H5BP/main.css";

// Core variables and mixins
@import "bootstrap/variables.less";
@import "_variables.less"; //MOD
@import "bootstrap/mixins.less";

// Reset and dependencies
@import "bootstrap/normalize.less";
@import "bootstrap/print.less";
//@import "bootstrap/glyphicons.less"; //MOD
@import "font-awesome/font-awesome.less"; //MOD

// Core CSS
@import "bootstrap/scaffolding.less";
@import "bootstrap/type.less";
@import "bootstrap/code.less";
@import "bootstrap/grid.less";
@import "bootstrap/tables.less";
@import "bootstrap/forms.less";
@import "bootstrap/buttons.less";

// Components
@import "bootstrap/component-animations.less";
@import "bootstrap/dropdowns.less";
@import "bootstrap/button-groups.less";
@import "bootstrap/input-groups.less";
@import "bootstrap/navs.less";
@import "bootstrap/navbar.less"; // Contents of _navbar only partial
@import "bootstrap/breadcrumbs.less";
@import "bootstrap/pagination.less";
@import "bootstrap/pager.less";
@import "bootstrap/labels.less";
@import "bootstrap/badges.less";
@import "bootstrap/jumbotron.less";
@import "bootstrap/thumbnails.less";
@import "bootstrap/alerts.less";
@import "bootstrap/progress-bars.less";
@import "bootstrap/media.less";
@import "bootstrap/list-group.less";
@import "bootstrap/panels.less";
@import "bootstrap/responsive-embed.less";
@import "bootstrap/wells.less";
@import "bootstrap/close.less";

// Components w/ JavaScript
@import "bootstrap/modals.less";
@import "bootstrap/tooltip.less";
@import "bootstrap/popovers.less";
//@import "bootstrap/carousel.less"; >> ENTIRE FILE MOVED TO _CAROUSEL

// Utility classes
@import "bootstrap/utilities.less";
@import "bootstrap/responsive-utilities.less";

// Other custom files > MOD
@import "_navbar.less"; //MOD
@import "_carousel.less"; //MOD
@import "footer.less";
@import "background.less";
@import "queries.less";
@import "contact.less";
@import "modal-nav.less";
@import "preloader.less";
@import "about.less";
@import "projects.less";

