@input-text : @brand-secondary;
@input-focus : @brand-secondary;
@bg-hover : rgba(40, 71, 52, 0.10);
@box-bg : rgba(255,255,255,0.6);
//@size : 0.875em;

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: @input-text;

}

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {
    color: @input-focus;
}

input::-moz-placeholder, textarea::-moz-placeholder {
    color: @input-text;
}

input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {
    color: @input-focus;
}

//noinspection CssInvalidPseudoSelector
input::placeholder, textarea::placeholder {
    color: @input-text;
}

//noinspection CssInvalidPseudoSelector
input:focus::placeholder, textarea::focus:placeholder {
    color: @input-focus;
}

//noinspection CssInvalidPseudoSelector
input::-ms-placeholder, textarea::-ms-placeholder {
    color: @input-text;
}

//noinspection CssInvalidPseudoSelector
input:focus::-ms-placeholder, textarea:focus::-ms-placeholder {
    color: @input-focus;
}

/* on hover placeholder */

input:hover::-webkit-input-placeholder, textarea:hover::-webkit-input-placeholder {
    //color: #e2dedb;
    color: @input-text;
}

input:hover:focus::-webkit-input-placeholder, textarea:hover:focus::-webkit-input-placeholder {
    color: @input-focus;
}

input:hover::-moz-placeholder, textarea:hover::-moz-placeholder {
    color: @input-text;
}

input:hover:focus::-moz-placeholder, textarea:hover:focus::-moz-placeholder {
    color: @input-focus;
}

//noinspection CssInvalidPseudoSelector
input:hover::placeholder, textarea:hover::placeholder {
    color: @input-text;
}

//noinspection CssInvalidPseudoSelector
input:hover:focus::placeholder, textarea:hover:focus::placeholder {
    color: @input-focus;
}

//noinspection CssInvalidPseudoSelector
input:hover::placeholder, textarea:hover::placeholder {
    color: @input-text;
}

//noinspection CssInvalidPseudoSelector
input:hover:focus::-ms-placeholder, textarea:hover::focus:-ms-placeholder {
    color: @input-focus;
}


#form {
    position: relative;
    width: 30vw;
    margin: 25px auto 100px auto;
    border: solid 1px @bg-hover;
    border-radius: 4px;
    z-index: 10;
}

input {
    width: 100%;
    height: 50px;
    padding: 0 15px 0 15px;

    background: @box-bg;

    outline: none;
    color: @brand-secondary;

    border: none;
    border-bottom: none;

    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;

}

input:hover {
    background: @bg-hover;
    color: @input-text;
}

.contact {
    margin-left: 35vw;
    a {
        color: @brand-secondary;
        &:hover, &:focus {
            text-decoration: none;
            font-weight: bold;
            color: @brand-highlight;
        }
    }
}

textarea {
    width: 100%;
    //max-width: 470px;
    display: block;
    height: 110px;
    max-height: 110px;
    padding: 15px;

    background: @box-bg;
    outline: none;

    color: #726659;

    border: none;

    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
}

textarea:hover {
    background: @bg-hover; // brand-secondary with reduced opacity
    color: @input-text;
}

#submit {
    //width: 502px;

    padding: 0;
    //margin: -5px 0px 0px 0px;

    //font-size: @size;
    color: @brand-secondary;
    //background: @box-bg;

    outline:none;
    cursor: pointer;

    border-top: solid 1px @bg-hover;
}

#submit:hover {
    background: @brand-secondary;
    border-radius: 0 0 4px 4px;
    color: white;
    font-weight: bold;
}

#darkness {
    background:rgba(0, 0, 0, 0.5);
    display:none;
    height:100vh;
    left:0;
    position:fixed;
    top:0;
    width:100vw;
    z-index:5;
}

#gotcha {
    display: none;
}


