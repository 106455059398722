ul.social {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  > li {
    display: inline-block;
    > a {
      display: inline-block;
      font-size: 18px;
      line-height: 30px;
      .square(30px); // see bootstrap/mixins.less
      border-radius: 36px;
      background-color: @brand-secondary;
      color: #fff;
      margin: 0 3px 3px 0;
      transition: all 0.2s ease;
      &:hover {
        text-decoration: none;
        background-color: transparent;
        color: @brand-secondary;
        transition: all 0.2s ease;
      }
    }
  }
}

footer[role="contentinfo"] {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 0px;
  text-align: center;
}

#copyright {
    font-size: 1.2rem;
}
