// Custom breakpoint changes

// TODO lots of testing for responsivity. Mobile forms are funky at the mo

@media (max-width: (@screen-sm-min - 1)) and (orientation: portrait) {
    .navbar-brand>img {
        height: (@navbar-height * 1.6);
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        width: auto;
    }
    body {
        font-size: (@font-size-base * 0.8);
    }

    @font-size-contact: (@font-size-base * 0.8);
    h1 {
        font-size: (@font-size-h1 * 0.75);
    }

    .mainCarousel {
        position: fixed;
        top: 20vh;
        width: 90vw;
        height: 80vh;
        left: 1rem;
        img {
            margin: auto;
        }
    }
    .slide {
        height: 60vh;
    }
    .carousel-inner {
        position: fixed;
        margin: 0;
        left: 0;
        width: 100vw;
        .item {
            width: 100vw;
        }
    }
    .carousel-control {
        &.left {
            position: fixed;
            left: 0;
        }
        &.right {
            position: fixed;
            right: 0;
        }
    }
    #form {
        display: none;
    }
    #about-head {
        display: none;
    }
    #projects-head {
        display: none;
    }
    h5.projects {
        font-weight: normal;
        margin: 0 0 0 0;
        text-align: center;
    }
    .projects-text {
        display: none;
    }
    h5.contact {
        margin: 0;
        text-align: center;
        font-size: (@font-size-base * 1);
        #map::after {
            content: " ";
            display: block;
            clear: both;
        }
    }
    .navbar-toggle {
        visibility: hidden;
    }
}

@media (max-width: (@screen-sm-min - 1)) and (orientation: landscape) {
    .navbar-brand>img {
        height: (@navbar-height * 1.6);
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        width: auto;
    }
    body {
        font-size: (@font-size-base * 0.8);
    }
    h1 {
        font-size: (@font-size-h1 * 0.75);
    }
    @font-size-contact: (@font-size-base * 0.8);

    .mainCarousel {
        position: fixed;
        top: 20vh;
        width: 90vw;
        height: 80vh;
        left: 1rem;
        img {
            margin: auto;
        }
    }
    .slide {
        height: 60vh;
    }
    .carousel-inner {
        position: fixed;
        margin: 0;
        left: 0;
        width: 100vw;
        .item {
            width: 100vw;
        }
    }
    .carousel-control {
        &.left {
            position: fixed;
            left: 0;
        }
        &.right {
            position: fixed;
            right: 0;
        }
    }
    #form {
        display: none;
    }
    h5.contact {
        margin: 0;
        text-align: center;
        font-size: (@font-size-base * 1);
    }

    #about-head {
        display: none;
    }
    #projects-head {
        display: none;
    }
    h5.projects {
        font-weight: normal;
        margin: 0 0 0 0;
        text-align: center;
    }
    .projects-text {
        display: none;
    }
    .navbar-toggle {
        visibility: hidden;
    }
}





@media (min-width: @screen-sm-min) {
    .navbar-brand>img {
        height: (@navbar-height * 1.6);
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: auto;
    }
    body {
        font-size: (@font-size-base * 0.8);
    }
    @font-size-contact: (@font-size-base * 0.8);
    #contact-head {
        display: none;
    }
}
@media (min-width: @screen-md-min) {
    .navbar-brand>img {
        height: (@navbar-height * 1.8);
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: auto;
    }
    body {
        font-size: (@font-size-base * 0.9);
    }
    @font-size-contact: (@font-size-base * 0.8);
    #contact-head {
        display: none;
    }
}
@media (min-width: @screen-lg-min) {
    .navbar-brand>img {
        height: (@navbar-height * 2);
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: auto;
    }
    body {
        font-size: @font-size-base;
    }
    @font-size-contact: (@font-size-base * 0.8);
    #contact-head {
        display: none;
    }
    #contact-break {
        display: none;
    }
    h5.projects {
        font-weight: bold;
        margin: 0 35vw 0 35vw;
    }

}
