.popNav { // draw lines below links in modal
    > li > a {
        color: @navbar-default-link-color;
        font-weight: bold;

        &:hover,
        &:focus {
            color: @navbar-default-link-hover-color;
            background-color: @navbar-default-link-hover-bg;
            position: relative; //MOD
        }

        &:before { //MOD
            content: "";
            position: absolute;
            width: 50%;
            height: 3px;
            bottom: 1.5rem;
            left: 25%;
            right: 25%;
            background-color: @brand-primary;
            opacity: 1;
            visibility: hidden;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: all 0.2s ease-in-out 0s;
            transition: all 0.2s ease-in-out 0s;
        }
        &:hover:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
    > .active > a {
        &,
        &:hover,
        &:focus {
            color: @navbar-default-link-active-color;
            background-color: @navbar-default-link-active-bg;
        }
    }
    > .disabled > a {
        &,
        &:hover,
        &:focus {
            color: @navbar-default-link-disabled-color;
            background-color: @navbar-default-link-disabled-bg;
        }
    }
}

#navModal {
    margin: auto;
    width: 60%;
    height: 60%;

    a {
        font-size: xx-large;
        text-align: center;
        color: rgba(255,255,255,1);
        line-height: 6rem;
    }
    .modal-body {
        //background-color: blue;

    }
    .modal-content{
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none;
        background-color: @brand-secondary;
        padding: 2rem 0;
        opacity: 1;
    }
    .modal-backdrop {
        //opacity:0.2;
        background-color: black;
        //filter: alpha(opacity=20);
    }


}

