.pg-canvas
{
    height: 100vh;
    width: 100vw;
    background: no-repeat right top fixed;
}

#particles {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
