//Mods from bootstrap/variables.less
@brand-primary: #007844;
@brand-secondary: #284734;
@brand-highlight: #9a9500;
@font-family-sans-serif: "Quicksand", Helvetica, Arial, sans-serif;
@font-size-base:          20px;
@navbar-default-bg: transparent;
@navbar-default-link-color:                @brand-secondary;
@navbar-default-link-hover-color:          @brand-secondary;
@navbar-padding-horizontal:         1rem;
@text-color: @brand-secondary;
@carousel-control-width: 30%; // TODO add control width changes to queries.less
