h5.projects {
    sup {
        font-weight: normal;
    }
}
.projects-text {
    margin: 0 35vw 0 35vw;
}

#tm {
    font-size: 55%;
    top: -0.8em;
}

#sm {
    top: -0.4em;
}
