.navbar-brand {
    padding: 0px;
}
.navbar-brand>img {
    height: (@navbar-height * 2);
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: auto;
}

.navbar-brand>img#logo_color {
    position: fixed;
    top: 0;
    opacity: 0;
    transition: opacity 0.2s ease-out;
    &:hover {
        opacity: 1;
        transition: opacity 0.2s ease-out;
    }
}

.navbar-nav { // draw lines below links
    > li > a {
        color: @navbar-default-link-color;
        //font-weight: bold;

        &:hover,
        &:focus {
            color: @navbar-default-link-hover-color;
            background-color: @navbar-default-link-hover-bg;
            position: relative; //MOD
        }

        &:before { //MOD
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: @brand-highlight;
            visibility: hidden;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: all 0.2s ease-in-out 0s;
            transition: all 0.2s ease-in-out 0s;
        }
        &:hover:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
    > .active > a {
        &,
        &:hover,
        &:focus {
            color: @navbar-default-link-active-color;
            background-color: @navbar-default-link-active-bg;
        }
    }
    > .disabled > a {
        &,
        &:hover,
        &:focus {
            color: @navbar-default-link-disabled-color;
            background-color: @navbar-default-link-disabled-bg;
        }
    }
}

// Navbar toggle
//
// Custom button for toggling the `.navbar-collapse`, powered by the collapse
// JavaScript plugin.

.navbar-toggle {
    position: relative;
    float: right;
    margin-right: @navbar-padding-horizontal;
    margin-top: 1rem;
    padding: 9px 10px;
    //.navbar-vertical-align(34px);
    background-color: transparent;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    border-radius: @border-radius-base;

    // We remove the `outline` here, but later compensate by attaching `:hover`
    // styles to `:focus`.
    &:focus {
        outline: 0;
    }

    // Bars
    .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
    }
    .icon-bar + .icon-bar {
        margin-top: 4px;
    }

    @media (min-width: @grid-float-breakpoint) {
        display: none;
    }
}

.navbar-collapse {
    overflow-x: visible;
    padding-right: @navbar-padding-horizontal;
    padding-left:  @navbar-padding-horizontal;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
    &:extend(.clearfix all);
    -webkit-overflow-scrolling: touch;


    &.in {
        overflow-y: auto;
        background-color: rgba(0,0,0,0.5); //MOD
        //-webkit-transform: translateY(20vh);
        //transform: translateY(20vh);
        -webkit-transition: background-color 0.2s;
        transition: background-color 0.2s;
    }

    @media (min-width: @grid-float-breakpoint) {
        width: auto;
        border-top: 0;
        box-shadow: none;

        &.collapse {
            display: block !important;
            height: auto !important;
            padding-bottom: 0; // Override default setting
            overflow: visible !important;
        }

        &.in {
            overflow-y: visible;
        }

        // Undo the collapse side padding for navbars with containers to ensure
        // alignment of right-aligned contents.
        .navbar-fixed-top &,
        .navbar-static-top &,
        .navbar-fixed-bottom & {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.navbar-toggle {
    overflow: hidden;
    .icon-bar {
        transition: opacity, transform;
        transition-duration: 0.2s;
        transition-timing-function: linear;
    }

    &:not(.collapsed) {
        .icon-bar {
            &:nth-child(1) {
                transform: translateY(6px);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                transform: translateY(-6px);
            }
        }
    }
}

.navbar-header {
    margin: 0;
}

nav>.container {
    padding: 0rem 1rem;
}


